<template>

  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-row>
          <ion-col>
            <ion-button @click="backMenu()">
              Regresar
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-title style="margin: 10px;">INGRESSIFY</ion-title>
          </ion-col>
          <ion-col>
            <img style="width: 40px;" :src="require('@/images/logosoloingre1024.png')" alt=".">
          </ion-col>
        </ion-row>
      </ion-toolbar>
    </ion-header>

    <ion-content>

      <!-- Cuestionario Datos Principales -->
      <ion-card>
        <ion-card-header>
          <ion-card-title>CUESTIONARIO</ion-card-title>
        </ion-card-header>

        <ion-card-content>
          <ion-grid>
            <ion-row>
              <ion-col size="12">
                <h1>{{ alias }}</h1>
              </ion-col>
              <br>
              <!-- <ion-col size="12">
                <ion-label>Persona que Entrega</ion-label>
                <ion-item>
                  <ion-select @ionChange="selectReponsable($event)" placeholder="Seleccione al responsable">
                    <ion-select-option
                        v-for="item in responsables"
                        v-bind:value="{ id: item.id, firstname: item.firstname, lastname: item.lastname }"
                        v-bind:key="item">
                      {{ item.firstname }} {{ item.lastname }}
                    </ion-select-option>
                  </ion-select>
                </ion-item>
              </ion-col>
              <br>
              <ion-col size="12" v-if="schoolslenght = true">
                <ion-label>Escuela: {{ campusName }}</ion-label>
              </ion-col>
              <ion-col size="12" v-if="schoolslenght = false">
                <ion-label>Escuela</ion-label>
                <ion-item>
                  <ion-select @ionChange="selectSchool($event)" placeholder="Seleccione la escuela">
                    <ion-select-option
                        v-for="item in escuelas"
                        v-bind:value="{ id: item.id, name: item.campus_name }"
                        v-bind:key="item">
                      {{ item.campus_name }}
                    </ion-select-option>
                  </ion-select>
                </ion-item>
              </ion-col> -->
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card>

      <!-- Botones para agregar cuestionarios -->
      <div>
      <!-- <ion-button v-if="buttonDisabled" @click="addFormResponsable()">+ Cuestionario Responsable</ion-button> -->
        <ion-button v-if="buttonDisabled" @click="addForm()">Agregar Cuestionario</ion-button>

      </div>

      <!-- Agregar cuestionario de hijo -->
      <ion-card v-if="statusForm">
        <ion-card-content>
          <ion-col size="6">
            <ion-label>Alumno</ion-label>
            <ion-item>
              <ion-select @ionChange="selectChanged" placeholder="Seleccione un alumno">
                <ion-select-option
                    v-for="item in hijos"
                    v-bind:value="{ id: item.id, name: item.firstname,  campusId: item.campus_id, is_child: true }"
                    v-bind:key="item">
                  {{ item.firstname }}
                </ion-select-option>
              </ion-select>
            </ion-item>
          </ion-col>
          <ion-grid>
            <ion-row>
              <ion-col size="12">

                <div v-for="item in items" v-bind:key="item">
                  <h2>{{ item.question }}</h2>

                  <ion-segment @ionChange="segmentChanged($event)">
                    <ion-segment-button value="true">
                      <ion-label>Si</ion-label>
                    </ion-segment-button>
                    <ion-segment-button value="false">
                      <ion-label>No</ion-label>
                    </ion-segment-button>
                  </ion-segment>

                  <br>
                </div>

              </ion-col>
              <div v-if="btnsForm">
                <ion-button @click="finishForm">Agregar</ion-button>
                <ion-button color="medium" @click="closeForm">Cancelar</ion-button>
              </div>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card>

      <!-- Agregar cuestionario de responsable
      <ion-card v-if="statusFormResponsable">
        <ion-card-content>
          <ion-col size="6">
            <ion-label>Persona que entrega</ion-label>
            <ion-item>
              <ion-select @ionChange="selectChanged" placeholder="Seleccione un responsable">
                <ion-select-option
                    v-for="item in responsables"
                        v-bind:value="{ id: item.id, name: item.firstname, lastname: item.lastname, is_child: false }"
                        v-bind:key="item">
                      {{ item.firstname }} {{ item.lastname }}
                </ion-select-option>
              </ion-select>
            </ion-item>
          </ion-col>
          <ion-grid>
            <ion-row>
              <ion-col size="12">

                <div v-for="item in items" v-bind:key="item">
                  <h2>{{ item.question }}</h2>

                  <ion-segment @ionChange="segmentChanged($event)">
                    <ion-segment-button value="true">
                      <ion-label>Si</ion-label>
                    </ion-segment-button>
                    <ion-segment-button value="false">
                      <ion-label>No</ion-label>
                    </ion-segment-button>
                  </ion-segment>

                  <br>
                </div>

              </ion-col>
              <ion-button expand="block" @click="finishFormResponsable">Agregar</ion-button>
              <ion-button expand="block" color="medium" @click="closeFormResponsable">Cancelar</ion-button>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card> -->

      <!-- Listado de cuestionarios agregados -->
      <ion-card>
        <ion-card-content>
          <h1><strong>Agregados</strong></h1>
          <div>
            <br>
            <div v-for="item in alumnos" v-bind:key="item">
              <ion-row>
                <ion-col size="2">
                  <img style="width: 25px;" :src="require('@/icons/checkmark-circle-outline.svg')">
                </ion-col>
                <ion-col>
                  <h2>Nombre: {{ item.name }}</h2>
                </ion-col>
              </ion-row>
            </div>
          </div>
        </ion-card-content>
      </ion-card>

      <ion-button v-if="finishForms" expand="block" @click="completeForms">Terminar</ion-button>
    </ion-content>
  </ion-page>

</template>

<script>
import {
  IonCol,
  IonGrid,
  IonRow,
  IonPage,
  IonCardContent,
  IonCardHeader,
  IonIcon,
  IonCardTitle,
  IonSelect,
  IonSelectOption,
  IonSegmentButton,
  IonSegment,
  toastController,
  alertController,
  loadingController
} from '@ionic/vue';
import { useRouter } from 'vue-router';
import router from "../router";
import FamilyService from "@/services/FamilyService";
import SurveyForm from "@/services/SurveyForm";
import { TokenUtils } from '@/utils/TokenUtils';
//import AuthService from '@/services/AuthService';
//import {mapGetters} from "vuex";

export default {
  name: "form",
  //computed: {
  //  ...mapGetters({
  //    formData: 'formData',
  //    studentForm: 'studentForm',
  //  }),
  //},
  components: {
    IonCol, IonGrid, IonRow, IonPage, IonCardContent, IonCardHeader, IonSelect, IonSelectOption,
    IonCardTitle, IonSegmentButton, IonSegment
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  data: () => ({
    alias: null,
    token: null,
    responsables: null,
    escuelas: null,
    hijos: null,
    items: null,
    familyId: null,
    currentModal: null,
    alumnoSelected: null,
    alumnos: [],
    responsableSelected: null,
    schoolSelected: null,
    statusForm: 0,
    statusFormResponsable: 0,
    buttonDisabled: 1,
    finishForms: 0,
    formAdd: 0,
    survey: null,
    form: [],
    forms: [],
    schoolslenght: false,
    campusName: null,
    btnsForm: 0,
  }),
  methods: {
    backMenu() {
      router.push(`/menu/?token=${this.$route.query.token}`);
      this.alumnos = [];
      this.forms = [];
      this.survey = null;
      this.items = null;
    },
    async addForm() {
      this.btnsForm = 0;
      this.statusForm = 1;
      this.buttonDisabled = 0;
      if (this.hijos == null) {
        this.hijos = await FamilyService.getFamilyChildrenCampus(this.familyId);
      }
    },
    /*async addFormResponsable() {
      this.statusFormResponsable = 1;
      this.buttonDisabled = 0;
    },
    async selectSchool(event) {
      this.schoolSelected = event.detail.value;
      console.log('selectEscuela', this.schoolSelected);
      this.survey = await SurveyForm.getSurvey(this.schoolSelected.id);
      this.items = this.survey.data;
      console.log('items', this.items);
    },
    selectReponsable(event) {
      this.responsableSelected = event.detail.value;
      console.log('responsableSelected', this.responsableSelected);
    },*/
    async selectChanged(event) {
      this.items = null;
      this.btnsForm = 0;
      let alumnoEqual = 0;
      this.alumnoSelected = event.detail.value;
      console.log('alumnoSelected', this.alumnoSelected);
      console.log('alumnos', this.alumnos);

      if(this.alumnos.length !== 0) {
        for(let i = 0; i < this.alumnos.length; i++) {
          console.log('this.alumnos[i]', this.alumnos[i]);
          if(this.alumnos[i].id === this.alumnoSelected.id) {
            alumnoEqual = 1;
          }
        }
      }

      if(alumnoEqual === 1) {
        const alert = await alertController.create({
          header: 'Error',
          message: 'El alumno seleccionado ya tiene un cuestionario contestado',
          buttons: ['Ok']
        });
        await alert.present();
      } else {
        this.survey = await SurveyForm.getSurvey(this.alumnoSelected.id);
        console.log('survey', this.survey);
        if (this.survey.is_form === true) {
          const alert = await alertController.create({
            header: 'Error',
            message: 'El alumno seleccionado ya cuenta con un cuestionario activo',
            buttons: ['Ok']
          });
          await alert.present();
          this.btnsForm = 0;
        } else {
          this.items = this.survey.data;
          this.btnsForm = 1;
        }
      }
    },
    segmentChanged(event) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      const question = { question_number: 1, value: event.detail.value }
      this.form.push(question);
    },
    finishForm() {
      this.alumnos.push(this.alumnoSelected);
      console.log('alumnos', this.alumnos);
      this.alumnoSelected = null;

      this.forms.push(this.form);
      this.form = [];
      this.items = null;

      console.log('forms', this.forms);

      if(this.alumnos.length === this.hijos.length) {
        this.buttonDisabled = 0;
      } else {
        this.buttonDisabled = 1;
      }

      this.statusForm = 0;
      this.finishForms = 1;
    },
    closeForm() {
      this.survey = null;
      this.items = null;
      this.statusForm = 0;
      this.buttonDisabled = 1;
    },
    finishFormResponsable() {
      this.alumnos.push(this.alumnoSelected);
      this.alumnoSelected = null;

      this.forms.push(this.form);
      this.form = [];

      //console.log('alumnos', this.alumnos);
      //console.log('forms', this.forms);

      this.statusFormResponsable = 0;
      this.buttonDisabled = 1;
      this.finishForms = 1;
    },
    closeFormResponsable() {
      this.statusFormResponsable = 0;
      this.buttonDisabled = 1;
    },
    async completeForms() {
      const loading = await loadingController.create({
        message: 'Please wait...',
        duration: 3000
      });
      await loading.present();
      /*console.log(this.responsableSelected);
      console.log(this.schoolSelected);
      if(this.responsableSelected === '' || this.responsableSelected === undefined || this.responsableSelected === null ||
          this.schoolSelected === '' || this.schoolSelected === undefined || this.schoolSelected === null) {
        const toast = await toastController.create({
          color: 'dark',
          duration: 2000,
          message: 'Falta llenar los datos del responsable y la escuela',
          showCloseButton: true
        });

        await toast.present();
      } else {*/
      const formsAnswersArray = [];
      for(let i=0; i<this.forms.length; i++) {
        const formsAnswers = {
          // eslint-disable-next-line @typescript-eslint/camelcase
          owner_id: this.alumnos[i].id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          is_child: this.alumnos[i].is_child,
          // eslint-disable-next-line @typescript-eslint/camelcase
          survey_id: this.survey.id,
          answers: this.forms[i]
        };
        formsAnswersArray.push(formsAnswers);
      }
      console.log('formsAnswersArray', formsAnswersArray);
      const formsAnswersJson = JSON.stringify(formsAnswersArray);
      console.log('formsAnswersJson', formsAnswersJson);
      const response = await SurveyForm.createFormChildren(this.familyId, formsAnswersJson);
      console.log('response', response);
      if(response.message === "Operacion completada con exito") {
        setTimeout(function() {
          loading.dismiss()
        }, this.timeout);
        this.buttonDisabled = 1;
        this.finishForms = 0;
        router.push(`/menu/?token=${this.$route.query.token}`);
        this.alumnos = [];
      } else {
        setTimeout(function() {
          loading.dismiss()
        }, this.timeout);
        const toast = await toastController.create({
          color: 'dark',
          duration: 2000,
          message: 'Error al enviar el formulario',
          showCloseButton: true
        });
        await toast.present();
      }
      this.alumnos = [];
      this.forms = [];
      this.survey = null;
      this.items = null;
      //}
    },
  },
  async mounted() {
    this.token = this.$route.query.token;
    console.log('token', this.token);
    TokenUtils.setToken(this.token);
    this.tokenDecode = TokenUtils.getJwtData();
    console.log('tokenDecode', this.tokenDecode);
    this.alias = this.tokenDecode.alias;
    this.familyId = this.tokenDecode.sub;

    /*this.responsables = await FamilyService.getFamilyAuthorized(this.familyId);
    console.log('responsablesData', this.responsables);

    this.escuelas = await FamilyService.getFamilyCampus(this.familyId);
    console.log('escuelas', this.escuelas);
    if(this.escuelas.length === 1) {
      this.schoolslenght = true;
      this.schoolSelected = this.escuelas[0];
      this.campusName = this.schoolSelected.campus_name;
      this.survey = await SurveyForm.getSurvey(this.schoolSelected.id);
      this.items = this.survey.data;
    }*/

    if (this.hijos == null) {
      this.hijos = await FamilyService.getFamilyChildrenCampus(this.familyId);
    }
    console.log('hijos', this.hijos)

    this.alumnos = [];
    this.forms = [];
    this.buttonDisabled = 1;
  },
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
