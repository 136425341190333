import Axios from '@/services/Axios';
//import { ErrorResponse } from '../common/models/ErrorResponse';
import { Survey } from '../common/models/Survey';

export default {

    // eslint-disable-next-line @typescript-eslint/camelcase
    async getSurvey(campus_id: any){
        // eslint-disable-next-line @typescript-eslint/camelcase
      const response = await Axios.get(`/mobile/survey?child_id=${campus_id}`);
      return response.data;
    },

    // eslint-disable-next-line @typescript-eslint/camelcase
    async createForm(family_id: any, answers_data: any) {

        const formData = new FormData();
        // eslint-disable-next-line @typescript-eslint/camelcase
        formData.append('family_id', family_id);
        // eslint-disable-next-line @typescript-eslint/camelcase
        formData.append('answers_data', answers_data);

        let response = null;
        try {
            response = await Axios.post('/mobile/form', formData);
        } catch(e) {
            console.log(e);
            console.error(e.response.data.message);
            //response = new ErrorResponse(e.response.data);
            return e.response.data;
        }
        //response = response.data;
        return response.data;
    },

    // eslint-disable-next-line @typescript-eslint/camelcase
    async createFormChildren(family_id: any, answers_data: any) {

        const formData = new FormData();
        // eslint-disable-next-line @typescript-eslint/camelcase
        formData.append('family_id', family_id);
        // eslint-disable-next-line @typescript-eslint/camelcase
        formData.append('answers_data', answers_data);

        let response = null;
        try {
            response = await Axios.post('/mobile/form/children', formData);
        } catch(e) {
            console.log(e);
            console.error(e.response.data.message);
            //response = new ErrorResponse(e.response.data);
            return e.response.data;
        }
        //response = response.data;
        return response.data;
    },

};
